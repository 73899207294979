export default [
  { name: 'Afghanistan', code: 'AF', name_fa: 'افغانستان' },
  { name: 'land Islands', code: 'AX', name_fa: 'جزایر الند' },
  { name: 'Albania', code: 'AL', name_fa: 'آلبانی' },
  { name: 'Algeria', code: 'DZ', name_fa: 'الجزایر' },
  { name: 'American Samoa', code: 'AS', name_fa: 'ساموآی آمریکا' },
  { name: 'AndorrA', code: 'AD', name_fa: 'آندورا' },
  { name: 'Angola', code: 'AO', name_fa: 'آنگولا' },
  { name: 'Anguilla', code: 'AI', name_fa: 'آنگویلا' },
  { name: 'Antigua and Barbuda', code: 'AG', name_fa: 'آنتیگوآ و باربودا' },
  { name: 'Argentina', code: 'AR', name_fa: 'آرژانتین' },
  { name: 'Armenia', code: 'AM', name_fa: 'ارمنستان' },
  { name: 'Aruba', code: 'AW', name_fa: 'آروبا' },
  { name: 'Australia', code: 'AU', name_fa: 'استرالیا' },
  { name: 'Austria', code: 'AT', name_fa: 'اتریش' },
  { name: 'Azerbaijan', code: 'AZ', name_fa: 'آذربایجان' },
  { name: 'Bahamas', code: 'BS', name_fa: 'باهاما' },
  { name: 'Bahrain', code: 'BH', name_fa: 'بحرین' },
  { name: 'Bangladesh', code: 'BD', name_fa: 'بنگلادش' },
  { name: 'Barbados', code: 'BB', name_fa: 'باربادوس' },
  { name: 'Belarus', code: 'BY', name_fa: 'بلاروس' },
  { name: 'Belgium', code: 'BE', name_fa: 'بلژیک' },
  { name: 'Belize', code: 'BZ', name_fa: 'بلیز' },
  { name: 'Benin', code: 'BJ', name_fa: 'بنین' },
  { name: 'Bermuda', code: 'BM', name_fa: 'برمودا' },
  { name: 'Bhutan', code: 'BT', name_fa: 'بوتان' },
  { name: 'Bolivia', code: 'BO', name_fa: 'بولیوی' },
  { name: 'Bosnia and Herzegovina', code: 'BA', name_fa: 'بوسنی و هرزگوین' },
  { name: 'Botswana', code: 'BW', name_fa: 'بوتسوانا' },
  { name: 'Bouvet Island', code: 'BV', name_fa: 'جزیره بووه' },
  { name: 'Brazil', code: 'BR', name_fa: 'برزیل' },
  { name: 'British Indian Ocean Territory', code: 'IO', name_fa: '' },
  { name: 'Brunei Darussalam', code: 'BN', name_fa: 'برونئی' },
  { name: 'Bulgaria', code: 'BG', name_fa: 'بلغارستان' },
  { name: 'Burkina Faso', code: 'BF', name_fa: 'بورکینافاسو' },
  { name: 'Burundi', code: 'BI', name_fa: 'بوروندی' },
  { name: 'Cambodia', code: 'KH', name_fa: 'کامبوج' },
  { name: 'Cameroon', code: 'CM', name_fa: 'کامرون' },
  { name: 'Canada', code: 'CA', name_fa: 'کانادا' },
  { name: 'Cape Verde', code: 'CV', name_fa: 'کیپ ورد' },
  { name: 'Cayman Islands', code: 'KY', name_fa: 'جزایر کیمن' },
  { name: 'Central African Republic', code: 'CF', name_fa: 'جمهوری آفریقای مرکزی' },
  { name: 'Chad', code: 'TD', name_fa: 'چاد' },
  { name: 'Chile', code: 'CL', name_fa: 'شیلی' },
  { name: 'China', code: 'CN', name_fa: 'چین' },
  { name: 'Christmas Island', code: 'CX', name_fa: 'جزایر کریسمس' },
  { name: 'Cocos (Keeling) Islands', code: 'CC', name_fa: '' },
  { name: 'Colombia', code: 'CO', name_fa: 'کلمبیا' },
  { name: 'Comoros', code: 'KM', name_fa: 'اتحاد قمر' },
  { name: 'Congo', code: 'CG', name_fa: 'کنگو' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD', name_fa: 'جمهوری دموکراتیک کنگو' },
  { name: 'Cook Islands', code: 'CK', name_fa: 'جزایر کوک' },
  { name: 'Costa Rica', code: 'CR', name_fa: 'کاستاریکا' },
  { name: "Cote D'Ivoire", code: 'CI', name_fa: '' },
  { name: 'Croatia', code: 'HR', name_fa: 'کرواسی' },
  { name: 'Cuba', code: 'CU', name_fa: 'کوبا' },
  { name: 'Cyprus', code: 'CY', name_fa: 'قبرس' },
  { name: 'Czech Republic', code: 'CZ', name_fa: 'جمهوری چک' },
  { name: 'Denmark', code: 'DK', name_fa: 'دانمارک' },
  { name: 'Djibouti', code: 'DJ', name_fa: 'جیبوتی' },
  { name: 'Dominica', code: 'DM', name_fa: 'دومینیکا' },
  { name: 'Dominican Republic', code: 'DO', name_fa: 'جمهوری دومینیکن' },
  { name: 'Ecuador', code: 'EC', name_fa: 'اکوادور' },
  { name: 'Egypt', code: 'EG', name_fa: 'مصر' },
  { name: 'El Salvador', code: 'SV', name_fa: 'السالوادور' },
  { name: 'Equatorial Guinea', code: 'GQ', name_fa: 'گینه استوایی' },
  { name: 'Eritrea', code: 'ER', name_fa: 'اریتره' },
  { name: 'Estonia', code: 'EE', name_fa: 'استونی' },
  { name: 'Ethiopia', code: 'ET', name_fa: 'اتیوپی' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', name_fa: 'جزایر فالکلند' },
  { name: 'Faroe Islands', code: 'FO', name_fa: 'جزایر فارو' },
  { name: 'Fiji', code: 'FJ', name_fa: 'فیجی' },
  { name: 'Finland', code: 'FI', name_fa: 'فنلاند' },
  { name: 'France', code: 'FR', name_fa: 'فرانسه' },
  { name: 'French Guiana', code: 'GF', name_fa: 'گویان فرانسه' },
  { name: 'French Polynesia', code: 'PF', name_fa: 'پولینزی فرانسوی' },
  { name: 'French Southern Territories', code: 'TF', name_fa: '' },
  { name: 'Gabon', code: 'GA', name_fa: 'گابن' },
  { name: 'Gambia', code: 'GM', name_fa: 'گامیا' },
  { name: 'Georgia', code: 'GE', name_fa: 'گرجستان' },
  { name: 'Germany', code: 'DE', name_fa: 'آلمان' },
  { name: 'Ghana', code: 'GH', name_fa: 'غنا' },
  { name: 'Gibraltar', code: 'GI', name_fa: '' },
  { name: 'Greece', code: 'GR', name_fa: 'یونان' },
  { name: 'Greenland', code: 'GL', name_fa: 'گرینلند' },
  { name: 'Grenada', code: 'GD', name_fa: 'گرانادا' },
  { name: 'Guadeloupe', code: 'GP', name_fa: '' },
  { name: 'Guam', code: 'GU', name_fa: 'گوام' },
  { name: 'Guatemala', code: 'GT', name_fa: 'گواتمالا' },
  { name: 'Guernsey', code: 'GG', name_fa: '' },
  { name: 'Guinea', code: 'GN', name_fa: 'گینه' },
  { name: 'Guinea-Bissau', code: 'GW', name_fa: 'گینه بیسائو' },
  { name: 'Guyana', code: 'GY', name_fa: 'گویان' },
  { name: 'Haiti', code: 'HT', name_fa: 'هاییتی' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM', name_fa: '' },
  { name: 'Holy See (Vatican City State)', code: 'VA', name_fa: '' },
  { name: 'Honduras', code: 'HN', name_fa: 'هندوراس' },
  { name: 'Hong Kong', code: 'HK', name_fa: 'هنگ‌کنگ' },
  { name: 'Hungary', code: 'HU', name_fa: 'مجارستان' },
  { name: 'Iceland', code: 'IS', name_fa: 'ایسلند' },
  { name: 'India', code: 'IN', name_fa: 'هند' },
  { name: 'Indonesia', code: 'ID', name_fa: 'اندونزی' },
  { name: 'Iran', code: 'IR', name_fa: 'ایران' },
  { name: 'Iraq', code: 'IQ', name_fa: 'عراق' },
  { name: 'Ireland', code: 'IE', name_fa: 'ایرلند' },
  { name: 'Isle of Man', code: 'IM', name_fa: '' },
  { name: 'Israel', code: 'IL', name_fa: 'اسراییل' },
  { name: 'Italy', code: 'IT', name_fa: 'ایتالیا' },
  { name: 'Jamaica', code: 'JM', name_fa: 'جاماییکا' },
  { name: 'Japan', code: 'JP', name_fa: 'ژاپن' },
  { name: 'Jersey', code: 'JE', name_fa: '' },
  { name: 'Jordan', code: 'JO', name_fa: 'اردن' },
  { name: 'Kazakhstan', code: 'KZ', name_fa: 'قزاقستان' },
  { name: 'Kenya', code: 'KE', name_fa: 'کنیا' },
  { name: 'Kiribati', code: 'KI', name_fa: '' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP', name_fa: 'کره شمالی' },
  { name: 'Korea, Republic of', code: 'KR', name_fa: 'کره جنوبی' },
  { name: 'Kuwait', code: 'KW', name_fa: 'کویت' },
  { name: 'Kyrgyzstan', code: 'KG', name_fa: 'قرقیزستان' },
  { name: "Lao People'S Democratic Republic", code: 'LA', name_fa: ' لائوس' },
  { name: 'Latvia', code: 'LV', name_fa: 'لاتویا' },
  { name: 'Lebanon', code: 'LB', name_fa: 'لبنان' },
  { name: 'Lesotho', code: 'LS', name_fa: 'لسوتو' },
  { name: 'Liberia', code: 'LR', name_fa: 'لیبریا' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', name_fa: 'لیبی' },
  { name: 'Liechtenstein', code: 'LI', name_fa: 'لیختنشتاین' },
  { name: 'Lithuania', code: 'LT', name_fa: 'لیتوانی' },
  { name: 'Luxembourg', code: 'LU', name_fa: 'لوگزامبورگ' },
  { name: 'Macao', code: 'MO', name_fa: 'ماکائو' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK', name_fa: 'مقدونیه' },
  { name: 'Madagascar', code: 'MG', name_fa: 'ماداگاسکار' },
  { name: 'Malawi', code: 'MW', name_fa: 'مالاوی' },
  { name: 'Malaysia', code: 'MY', name_fa: 'مالزی' },
  { name: 'Maldives', code: 'MV', name_fa: 'مالدیو' },
  { name: 'Mali', code: 'ML', name_fa: 'مالی' },
  { name: 'Malta', code: 'MT', name_fa: 'مالت' },
  { name: 'Marshall Islands', code: 'MH', name_fa: 'جزایر مارشال' },
  { name: 'Martinique', code: 'MQ', name_fa: '' },
  { name: 'Mauritania', code: 'MR', name_fa: 'موریتانی' },
  { name: 'Mauritius', code: 'MU', name_fa: 'موریس' },
  { name: 'Mayotte', code: 'YT', name_fa: '' },
  { name: 'Mexico', code: 'MX', name_fa: 'مکزیک' },
  { name: 'Micronesia, Federated States of', code: 'FM', name_fa: 'میکرونزی' },
  { name: 'Moldova, Republic of', code: 'MD', name_fa: 'مولداوی' },
  { name: 'Monaco', code: 'MC', name_fa: 'موناکو' },
  { name: 'Mongolia', code: 'MN', name_fa: 'مغولستان' },
  { name: 'Montenegro', code: 'ME', name_fa: 'مونتنگرو' },
  { name: 'Montserrat', code: 'MS', name_fa: '' },
  { name: 'Morocco', code: 'MA', name_fa: 'مراکش' },
  { name: 'Mozambique', code: 'MZ', name_fa: 'موزامبیک' },
  { name: 'Myanmar', code: 'MM', name_fa: 'میانمار' },
  { name: 'Namibia', code: 'NA', name_fa: 'نامیبیا' },
  { name: 'Nauru', code: 'NR', name_fa: 'نائورو' },
  { name: 'Nepal', code: 'NP', name_fa: 'نپال' },
  { name: 'Netherlands', code: 'NL', name_fa: 'هلند' },
  { name: 'Netherlands Antilles', code: 'AN', name_fa: 'آنتیل هلند' },
  { name: 'New Caledonia', code: 'NC', name_fa: '' },
  { name: 'New Zealand', code: 'NZ', name_fa: 'نیوزیلند' },
  { name: 'Nicaragua', code: 'NI', name_fa: 'نیکاراگوئه' },
  { name: 'Niger', code: 'NE', name_fa: 'نیجر' },
  { name: 'Nigeria', code: 'NG', name_fa: 'نیجریه' },
  { name: 'Niue', code: 'NU', name_fa: '' },
  { name: 'Norfolk Island', code: 'NF', name_fa: '' },
  { name: 'Northern Mariana Islands', code: 'MP', name_fa: 'جزایر ماریان شمالی' },
  { name: 'Norway', code: 'NO', name_fa: 'نروژ' },
  { name: 'Oman', code: 'OM', name_fa: 'عمان' },
  { name: 'Pakistan', code: 'PK', name_fa: 'پاکستان' },
  { name: 'Palau', code: 'PW', name_fa: 'پالائو' },
  { name: 'Palestinian Territory, Occupied', code: 'PS', name_fa: 'فلسطین' },
  { name: 'Panama', code: 'PA', name_fa: 'پاناما' },
  { name: 'Papua New Guinea', code: 'PG', name_fa: 'پاپوا گینه نو' },
  { name: 'Paraguay', code: 'PY', name_fa: 'پاراگوئه' },
  { name: 'Peru', code: 'PE', name_fa: 'پرو' },
  { name: 'Philippines', code: 'PH', name_fa: 'فیلیپین' },
  { name: 'Pitcairn', code: 'PN', name_fa: '' },
  { name: 'Poland', code: 'PL', name_fa: 'لهستان' },
  { name: 'Portugal', code: 'PT', name_fa: 'پرتغال' },
  { name: 'Puerto Rico', code: 'PR', name_fa: 'پورتو ریکو' },
  { name: 'Qatar', code: 'QA', name_fa: 'قطر' },
  { name: 'Reunion', code: 'RE', name_fa: '' },
  { name: 'Romania', code: 'RO', name_fa: 'رومانی' },
  { name: 'Russian Federation', code: 'RU', name_fa: 'روسیه' },
  { name: 'RWANDA', code: 'RW', name_fa: 'رواندا' },
  { name: 'Saint Helena', code: 'SH', name_fa: '' },
  { name: 'Saint Kitts and Nevis', code: 'KN', name_fa: 'سنت کیتس و نویس' },
  { name: 'Saint Lucia', code: 'LC', name_fa: '' },
  { name: 'Saint Pierre and Miquelon', code: 'PM', name_fa: '' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', name_fa: '' },
  { name: 'Samoa', code: 'WS', name_fa: 'ساموآ' },
  { name: 'San Marino', code: 'SM', name_fa: 'سن مارینو' },
  { name: 'Sao Tome and Principe', code: 'ST', name_fa: '' },
  { name: 'Saudi Arabia', code: 'SA', name_fa: 'عربستان' },
  { name: 'Senegal', code: 'SN', name_fa: 'سنگال' },
  { name: 'Serbia', code: 'RS', name_fa: 'صربستان' },
  { name: 'Seychelles', code: 'SC', name_fa: 'سیشل' },
  { name: 'Sierra Leone', code: 'SL', name_fa: 'سیرالئون' },
  { name: 'Singapore', code: 'SG', name_fa: 'سنگاپور' },
  { name: 'Slovakia', code: 'SK', name_fa: 'اسلوواکی' },
  { name: 'Slovenia', code: 'SI', name_fa: 'اسلوونی' },
  { name: 'Solomon Islands', code: 'SB', name_fa: 'جزایر سلیمان' },
  { name: 'Somalia', code: 'SO', name_fa: 'سومالی' },
  { name: 'South Africa', code: 'ZA', name_fa: 'آفریقای جنوبی' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS', name_fa: '' },
  { name: 'Spain', code: 'ES', name_fa: 'اسپانیا' },
  { name: 'Sri Lanka', code: 'LK', name_fa: 'سریلانکا' },
  { name: 'Sudan', code: 'SD', name_fa: 'سودان' },
  { name: 'Suriname', code: 'SR', name_fa: 'سورینام' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', name_fa: '' },
  { name: 'Swaziland', code: 'SZ', name_fa: 'سوازیلند' },
  { name: 'Sweden', code: 'SE', name_fa: 'سوئد' },
  { name: 'Switzerland', code: 'CH', name_fa: 'سوییس' },
  { name: 'Syrian Arab Republic', code: 'SY', name_fa: 'سوریه' },
  { name: 'Taiwan, Province of China', code: 'TW', name_fa: 'تایوان' },
  { name: 'Tajikistan', code: 'TJ', name_fa: 'تاجیکستان' },
  { name: 'Tanzania, United Republic of', code: 'TZ', name_fa: 'تانزانیا' },
  { name: 'Thailand', code: 'TH', name_fa: 'تایلند' },
  { name: 'Timor-Leste', code: 'TL', name_fa: 'تیمور شرقی' },
  { name: 'Togo', code: 'TG', name_fa: 'توگو' },
  { name: 'Tokelau', code: 'TK', name_fa: '' },
  { name: 'Tonga', code: 'TO', name_fa: 'تونگا' },
  { name: 'Trinidad and Tobago', code: 'TT', name_fa: 'ترینیداد' },
  { name: 'Tunisia', code: 'TN', name_fa: 'تونس' },
  { name: 'Turkey', code: 'TR', name_fa: 'ترکیه' },
  { name: 'Turkmenistan', code: 'TM', name_fa: 'ترکمنستان' },
  { name: 'Turks and Caicos Islands', code: 'TC', name_fa: '' },
  { name: 'Tuvalu', code: 'TV', name_fa: 'تووالو' },
  { name: 'Uganda', code: 'UG', name_fa: 'اوگاندا' },
  { name: 'Ukraine', code: 'UA', name_fa: 'اوکراین' },
  { name: 'United Arab Emirates', code: 'AE', name_fa: 'امارات متحده' },
  { name: 'United Kingdom', code: 'GB', name_fa: 'انگلیس' },
  { name: 'United States', code: 'US', name_fa: 'آمریکا' },
  { name: 'United States Minor Outlying Islands', code: 'UM', name_fa: '' },
  { name: 'Uruguay', code: 'UY', name_fa: 'اروگوئه' },
  { name: 'Uzbekistan', code: 'UZ', name_fa: 'ازبسکتان' },
  { name: 'Vanuatu', code: 'VU', name_fa: 'وانواتو' },
  { name: 'Venezuela', code: 'VE', name_fa: 'ونزوئلا' },
  { name: 'Viet Nam', code: 'VN', name_fa: 'ویتنام' },
  { name: 'Virgin Islands, British', code: 'VG', name_fa: '' },
  { name: 'Virgin Islands, U.S.', code: 'VI', name_fa: '' },
  { name: 'Wallis and Futuna', code: 'WF', name_fa: '' },
  { name: 'Western Sahara', code: 'EH', name_fa: 'صحرای غربی' },
  { name: 'Yemen', code: 'YE', name_fa: 'یمن' },
  { name: 'Zambia', code: 'ZM', name_fa: 'زامبیا' },
  { name: 'Zimbabw', code: 'Z', name_fa: 'زیمباوه' },
];
